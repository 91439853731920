<template>
    <div class="side-bar-overlay">
        <div class="side-bar"
             v-click-outside="close"
        >
            <div class="side-bar__header">
                <h2 class="side-bar__title">{{title}}</h2>
                <a href="#"
                   class="side-bar__close"
                   @click.prevent="close">
                    <i class="icon-close"></i>
                </a>
            </div>
            <div class="side-bar__body"
                 :class="{'side-bar__body--bg': background}"
            >
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SideBar',
    props: {
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        icon: {
            type: String,
        },
        background: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        close() {
            this.$emit('close')
        }
    },
    // When component is mounted
    mounted() {
        // when we open the sidebar we prevent the body from scrolling
        document.querySelector("body").classList.add('no-scroll');
    },
    // When component get's destroyed
    unmounted() {
        // when we close the side barr the body can scroll again
        document.querySelector("body").classList.remove('no-scroll');
    }
}
</script>
