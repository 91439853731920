<template>
    <div class="tooltip">
        <slot></slot>

        <div class="tooltip__body" :class="`tooltip__body--${direction}`" v-html="body"></div>
    </div>
</template>

<script>
export default {
    name: 'Tooltip',
    props: {
        body: {
            type: String,
        },
        direction: {
            type: String,
        },
    }
}
</script>
