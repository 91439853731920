<template>
    <div class="form__badges badges">
        <label class="form__badge" v-for="option in options" :key="option.value">
            <input
                type="radio"
                v-model="localValue"
                :value="option.value"
                :disabled="disabled"
                @click="uncheck(option.value)"
                class="badge__control"
            >
            <div class="badge badge--lg badge--hover"
                :class="{'badge--disabled': disabled}"
            >
                {{option.label}}
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'BadgeControl',
    data() {
        return {
            localValue: null,
        }
    },
    props: {
        modelValue: {
            type: [String, Number],
        },
        options: {
            type: Array,
        },
        disabled: {
            type: Boolean,
        }
    },
    watch: {
        localValue() {
            if( this.localValue !== this.modelValue) {
                this.$emit('update:modelValue', this.localValue);
            }
        },
        modelValue(value) {
            if(this.localValue !== value) {
                this.localValue = this.modelValue;
            }
        }
    },
    methods: {
        uncheck(val) {
            if(this.localValue === val) {
                this.localValue = null;
            }
        }
    },
    created() {
        this.localValue = this.modelValue;
    }
}
</script>
