<template>
    <div class="progress-bar">
        <div
            v-for="(tab, key) in tabs"
            :key="key"
            class="progress-bar__item"
            :class="{
                'progress-bar__item--active': tab.isActive,
                'progress-bar__item--completed': tab.isCompleted,
            }"
        >
            <span class="progress-bar__count">{{key + 1}}:</span> {{tab.title}}
            <span v-if="tab.isCompleted" class="icon-check-success icon-check-success--blue progress-bar__icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
        </div>
    </div>
</template>

<script>
import SelectWithImage from "@/components/form/controls/SelectWithImage";
export default {
    name: 'ProgressBar',
    components: {SelectWithImage},
    props: {
        tabs: {
            type: Object
        }
    }
}
</script>
