<template>
    <div v-if="panel === activePanel">
        <div class="row">
            <div class="col-md-6">
                <transition-group name="slide-up">
                    <div v-for="shapeSideModifiers in panel.shapeSideModifiers"
                         :key="shapeSideModifiers.sideId"
                         class="form__group"
                    >
                        <label class="form__label">
                            {{ getSideTitle(shapeSideModifiers.sideId, panel) }}
                        </label>
                        <select-with-image
                            v-model="shapeSideModifiers.sideModifierId"
                            :options="sideOptions"
                            :imagePlaceholderRounded="false"
                            :imageSmall="true"
                            :wrapText="true"
                            :disabled="disabled"
                        />
                    </div>
                </transition-group>
            </div>
            <div class="col-md-6">
                <transition name="slide-up">
                    <div class="form__group" v-if="panel.materialShape.legendSidesImageHref">
                        <label class="form__label">
                            Uw gezaagd product
                        </label>
                        <img :src="panel.materialShape.legendSidesImageHref">
                    </div>
                </transition>
                <transition name="slide-up">
                    <div v-if="stepData.productConfig.material.editorRemarks">
                        {{ stepData.productConfig.material.editorRemarks }}
                    </div>
                </transition>


            </div>
        </div>
    </div>
</template>

<script>
import SelectWithImage from "@/components/form/controls/SelectWithImage";

export default {
    name: 'SidesForm',
    components: {
        SelectWithImage,
    },
    data() {
        return {
            localPanel: null,
        }
    },
    props: {
        panel: {
            type: Object,
        },
        stepData: {
            type: Object,
        },
        activePanel: {
            type: Object,
        },
        disabled: {
            type: Boolean,
        },
    },
    computed: {
        /**
         * Posible options for sides with the correct format for the select with images
         * @returns {*}
         */
        sideOptions() {
            return this.stepData?.form?.sideModifiers.map(shape => {
                return {
                    id: shape.id,
                    title: shape.title,
                    image: shape.icon
                }
            })
        },
    },
    watch: {
        localPanel: {
            handler(localPanel) {
                this.$emit('update:panel', localPanel);
            },
            deep: true
        },
        panel(panel) {
            if (panel !== this.localPanel) {
                this.localPanel = panel;
            }
        }
    },
    methods: {
        /**
         * Get the side title by side id
         * @param sideId
         * @returns {*}
         */
        getSideTitle(sideId) {
            return this.panel.materialShape.sides.find(side => side.id === sideId).title;
        },
        /**
         * Set the default object for the side values as we can then sent this to the backend
         * @param panel
         */
        setShapeModifiers(panel) {
            panel.materialShape.sides.forEach(side => {
                panel.shapeSideModifiers.push({
                    sideId: side.id,
                    sideModifierId: null,
                })
            });
        }
    },
    created() {
        this.localPanel = this.panel;

        if ( ! this.localPanel.shapeSideModifiers.length) {
            this.setShapeModifiers(this.localPanel);
        }
    }
}
</script>
