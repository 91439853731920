<template>
    <step-wrapper
            :priceData="priceData"
            @triggerPreviousStep="previousStep"
            @submit="submit"
    >
        <transition name="slide-up">
            <div v-if="stepData">
                <panel-navigation
                        :panels="panels"
                        :activePanel="activePanel"
                        :can-create-new="! disabled"
                        :can-delete="! disabled"
                        :errors="activeErrors"
                        @addPanel="addPanel"
                        @editPanel="editPanel"
                        @deletePanel="deletePanel"
                />

                <panel-form v-for="(currentPanel, key) in panels"
                    v-model:panel="panels[key]"
                    :key="currentPanel.internalId"
                    :activePanel="activePanel"
                    :stepData="stepData"
                    :validationErrors="getValidationErrorsForPanel(currentPanel.internalId)"
                    :disabled="disabled"
                    @update:panel="panelUpdate"
                    @validate="setValidationMessage($event)"
                />
            </div>
        </transition>
    </step-wrapper>
</template>

<script>
import StepMixin  from "@/mixins/StepMixin";
import StepWrapper from "@/components/layout/StepWrapper";
import PanelNavigation from "@/components/steps/partials/PanelNavigation";
import PanelForm from "@/components/steps/partials/PanelForm";

const EMPTY_PANEL =  {
    title: "Nieuw paneel",
    grainDirection: false,
    sawClean: false,
    materialShapeId: null,
    amount: 1,
    shapePropertyValues: []
}


export default {
    name: 'PanelsStep',
    mixins: [StepMixin],
    components: {
        PanelForm,
        PanelNavigation,
        StepWrapper,
    },
    data() {
        return {
            stepUrl: baseHref + '/configurator/ajax/panels',
            panels: [],
            priceTimeout: [],
            activePanel: null,
        }
    },
    computed: {
        formData() {
            return {panels: this.panels}
        }
    },
    watch: {
        /**
         * When the stepData is updated we will check if we have any default data for ower panels
         */
        stepData() {
            // if there are any panels in the product config we will use them
            if (this.stepData.productConfig.panels.length) {
                this.panels = this.stepData.productConfig.panels.map(panel => {
                    return {
                        ...panel,
                        grainDirection: panel.grainDirection == 1,
                        sawClean: panel.sawClean == 1,
                        internalId: this.uuid(),
                        materialShapeId: panel.materialShape.id
                    }
                });

                // set the first panel as active
                if(this.stepSettings && this.stepSettings.panelId) {
                    // get the index
                    const panelIndex = this.panels.findIndex(panel => panel.id === this.stepSettings.panelId);
                    this.activePanel = this.panels[panelIndex];
                } else if(this.stepSettings && this.stepSettings.newPanel) {
                    // if not then we will add 1 default panel
                    this.addPanel();
                    this.activePanel = this.panels[this.panels.length - 1];
                } else {
                    this.activePanel = this.panels[0];
                }
            } else {
                // if not then we will add 1 default panel
                this.addPanel()
            }
        }
    },
    methods: {
        addPanel() {
            let newPanel = {...EMPTY_PANEL, internalId: this.uuid()};

            // If wa have been given a defaultShape we will set it as the initial materialShapeId
            if (this.stepData.form.defaultShape) {
                newPanel.materialShapeId = this.stepData.form.defaultShape;
            }

            this.panels.push(newPanel);
            this.activePanel = this.panels[this.panels.length - 1];
        },
        /**
         * @param panel
         */
        editPanel(panel) {
            this.activePanel = panel;
        },
        /**
         * @param panel
         */
        deletePanel(panel) {
            const panelKey = this.panels.indexOf(panel);
            const changeActive = this.activePanel === panel;

            this.panels.splice(panelKey, 1);

            if (changeActive) {
                // set the item before the deleted item as active if exist
                // otherwise we use hte next itel which uses the same index as the delete item now
                this.activePanel = this.panels[panelKey - 1]?
                        this.panels[panelKey - 1]:
                        this.panels[panelKey];
            }

            this.validate()
        },
        panelUpdate() {
            // add a Timeout so we don't spam the backend
            clearTimeout(this.priceTimeout);
            this.priceTimeout = setTimeout(this.updatePrice, 500);
        },
        /**
         * Get the validation errors for a specific panel
         * @param panelInternalId
         * @returns {*}
         */
        getValidationErrorsForPanel(panelInternalId) {
            return this.activeErrors[panelInternalId];
        },
        /**
         * We get the validation and only set the value of the field that has triggerd the validation
         * @param $event
         * @returns {Promise<void>}
         */
        async setValidationMessage($event) {
            await this.validate();
            const panelErrors = this.stepData.form.validationErrors.find(error => error.id === $event.panelId);

            if ( ! this.activeErrors[$event.panelId]) {
                this.activeErrors[$event.panelId] = {};
            }

            Object.keys(this.activeErrors[$event.panelId]).forEach(errorId => {
                this.activeErrors[$event.panelId][errorId] = null;
            })

            // if no errors we stop here
            if ( ! panelErrors || ! panelErrors.errors.length) {
                return;
            }

            panelErrors.errors.forEach(error => {
                this.activeErrors[$event.panelId][error.id] = error.errorMessage;
            });
        },
        /**
         * Set all error messages in step data as active errors
         */
        setAllValidationMessages() {
            this.stepData.form.validationErrors.forEach(panelError => {
                if (panelError.errors) {
                    panelError.errors.forEach(error => {
                        if ( ! this.activeErrors[panelError.id]) {
                            this.activeErrors[panelError.id] = {};
                        }

                        this.activeErrors[panelError.id][error.id] = error.errorMessage;
                    })
                }
            })
        },
        /**
         * Generate a uuid
         * @returns {string}
         */
        uuid() {
            return Date.now().toString(36) + Math.random().toString(36).substr(2);
        }
    }
}
</script>

