<template>
    <v-select
            v-model="localValue"
            :options="currentOptions"
            :reduce="object => object.id"
            :placeholder="'--'"
            :clearable="false"
            :disabled="disabled"
            label="title"
    >
        <template #option="{title, image, id}">
            <div class="option-with-image"
                 :class="{'option-with-image--default': id === null}"
            >
                <div v-if="image && imagePlaceholder"
                     class="option-with-image__image-wrapper"
                    :class="{
                        'option-with-image__image-wrapper--no-radius': !imagePlaceholderRounded,
                        'option-with-image__image-wrapper--small': imageSmall,
                        'option-with-image__image-wrapper--wrap': wrapText,
                    }"
                >
                    <img class="option-with-image__image"
                        :class="{
                            'option-with-image__image--small': imageSmall,
                        }"
                        :src="image"
                        :alt="title"
                    >
                </div>
                <img v-else-if="image && ! imagePlaceholder" class="option-with-image__image option-with-image__image--margin" :class="{ 'option-with-image__image--small': imageSmall}" :src="image" :alt="title">

                <div class="option-with-image__text">
                    {{title}}
                </div>
            </div>
        </template>

        <template #selected-option="{ title, image, id }">
            <div class="option-with-image"
                :class="{'option-with-image--default': id === null}"
            >
                <div class="option-with-image__image-wrapper" v-if="image && imagePlaceholder">
                    <img class="option-with-image__image" :class="{ 'option-with-image__image--small': imageSmall}" :src="image" :alt="title">
                </div>
                <img v-else-if="image && ! imagePlaceholder" class="option-with-image__image option-with-image__image--margin" :class="{ 'option-with-image__image--small': imageSmall}" :src="image" :alt="title">

                <div class="option-with-image__text">
                    {{title}}
                </div>
            </div>
        </template>

        <template #open-indicator="{ attributes }">
            <span v-bind="attributes"><i class="icon-chevron-down"></i></span>
        </template>

        <template #no-options>
            Geen resultaten
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'SelectWithImage',
    data() {
        return {
            localValue: null,
        }
    },
    props: {
        id: {
            type: String,
        },
        modelValue: {
            type: [String, Number],
        },
        options: {
            type: Array,
        },
        imagePlaceholder: {
            type: Boolean,
            default: true
        },
        imagePlaceholderRounded: {
            type: Boolean,
            default: true
        },
        wrapText: {
            type: Boolean,
            default: false
        },
        imageSmall: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
        }
    },
    computed: {
        currentOptions() {
            return [
            {
                id: null,
                title: '- Selecteer een optie -'
            }, ...this.options]
        }
    },
    watch: {
        localValue() {
            if(this.localValue !== this.modelValue) {
                this.$emit('update:modelValue', this.localValue);
            }
        },
        modelValue(newValue, oldValue) {
            if(this.localValue !== newValue) {
                this.localValue = this.modelValue;
            }
        }
    },
    created() {
        this.localValue = this.modelValue;
    }
}
</script>
