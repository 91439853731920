<template>
    <div class="badges badges--margin-lg panel-navigation">
        <a href="#"
           v-if="canCreateNew"
           class="button button--icon button--icon-small badges__button"
           @click.prevent="addPanel"
        >
            <i class="icon-add-thin"></i>
        </a>
        <a  href="#"
            v-for="(panel, key) in panels"
            :key="key"
            :class="{'badge--active': panel === activePanel,'badge--error': panelHasErrors(panel.internalId)}"
            class="badge badge--hover badge--xl badge--margin-lg"
            @click.prevent="editPanel(panel)"
        >
            <i v-if="panelHasErrors(panel.internalId)" class="icon-notification m-r-xs-5"></i>
            {{panel.title}}

            <button
               v-if="panels.length > 1 && canDelete"
               class="badge__delete"
                @click.prevent="deletePanel($event, panel)">
                <i class="icon-close "></i>
            </button>
        </a>
    </div>
</template>

<script>

export default {
    name: 'PanelNavigation',
    props: {
        panels: {
            type: Array,
        },
        activePanel: {
            type: Object,
        },
        errors: {
            type: Object,
            default: {},
        },
        canCreateNew: {
            type: Boolean,
            default: true,
        },
        canDelete: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean
        }
    },
    methods: {
        /**
         * Notify the parent that we want to add a new panel
         */
        addPanel() {
            this.$emit('addPanel')
        },
        /**
         * Edit panel by the key in the param
         * @param key
         */
        editPanel(key) {
            this.$emit('editPanel', key)
        },
        /**
         * Edit panel by the key in the param
         * @param key
         */
        deletePanel($event, key) {
            $event.stopPropagation();
            this.$emit('deletePanel', key)
        },
        panelHasErrors(panelInternalId) {
            return this.errors[panelInternalId] && Object.values(this.errors[panelInternalId]).filter(error => error !== null).length;
        }
    },
}
</script>
