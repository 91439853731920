<template>
    <div>
        <transition name="fade">
            <side-bar
                v-if="show"
                :title="title"
                :description="description"
                :icon="icon"
                :background="activeStep === 'TipsStep'"
                @close="attemptStopConfigurator"
            >
                <progress-bar
                    v-if="progressTabs && activeStep !== 'ThanksStep'"
                    :tabs="progressTabs"
                />

                <component
                    :is="activeStep"
                    :productConfigId="productConfigId"
                    :stepSettings="stepSettings"
                    :disabled="disabledState"
                    @clearstepSettings="clearstepSettings"
                    @updateHeader="updateHeader"
                    @updateProgressTabs="updateProgressTabs"
                    @previousStep="previousStep"
                    @nextStep="nextStep"
                    @goToStep="goToStep"
                    @forceClose="stopConfigurator"
                    @close="attemptStopConfigurator"
                    @setProductConfigId="setProductConfigId"
                    @changeLoading="changeLoading"
                />

                <loader :loading="loading"/>
            </side-bar>
        </transition>


        <confirmation-modal
            v-model:show="showAlert"
            @close="stopConfigurator"
        />
    </div>
</template>

<script>
import SideBar from './components/layout/SideBar'
import TipsStep from './components/steps/TipsStep'
import MaterialStep from './components/steps/MaterialStep'
import PanelsStep from './components/steps/PanelsStep'
import SidesStep from './components/steps/SidesStep'
import OrderStep from "@/components/steps/OrderStep";
import ThanksStep from "@/components/steps/ThanksStep";
import ProgressBar from "@/components/layout/ProgressBar";
import ConfirmationModal from "@/components/layout/ConfirmationModal";
import Loader from "@/components/layout/Loader";

export default {
    name: 'App',
    components: {
        ProgressBar,
        SideBar,
        TipsStep,
        MaterialStep,
        PanelsStep,
        SidesStep,
        OrderStep,
        ThanksStep,
        ConfirmationModal,
        Loader,
    },
    data() {
        return {
            show: false,
            disabledState: false,
            steps: [
                'TipsStep',
                'MaterialStep',
                'PanelsStep',
                'SidesStep',
                'OrderStep',
                'ThanksStep',
            ],
            activeStep: null,
            productConfigId: null,
            title: null,
            description: null,
            icon: null,
            progressTabs: null,
            stepSettings: null,
            showAlert: false,
            loading: false,
        }
    },
    methods: {
        startNewConfiugrator(e) {
            this.disabledState = false;
            this.productConfigId = null;
            this.activeStep = null;
            this.startConfiugrator(e);
        },
        /**
         * We start the configurator by showing the sidebar
         * If no step is active we start with the first one
         * @param e
         */
        startConfiugrator(e) {
            e.preventDefault();
            e.stopPropagation();

            if(e.type === 'trigger-product-configurator') {
                // use the product config given in the event
                this.productConfigId = e.detail.productConfigId;

                // if a step is given use the given one else use the 2e step
                this.activeStep = e.detail.step ?
                        this.steps[this.steps.indexOf(e.detail.step)] :
                        this.steps[1];

                // We can pass aditional info to a step via the stepSettings
                // if given we will set it
                if(e.detail.stepSettings) {
                    this.stepSettings = e.detail.stepSettings;
                }

                if(e.detail.disabled) {
                    this.disabledState = true;
                }
            }

            if(! this.activeStep) {
                this.activeStep = this.steps[0];
            }

            this.show = true;
        },
        /**
         * Attempt stop configuration
         * We show an alert to make sure the client knows he will lose his unsaved changes
         * If we are in the disabledState we will close the configurator immediately
         */
        attemptStopConfigurator() {
            if (this.disabledState || this.activeStep === 'ThanksStep') {
                this.stopConfigurator();
                return;
            }

            this.showAlert = true;
        },
        /**
         * Stop configuration we keep the current step active
         */
        stopConfigurator() {
            this.show = false;

            const triggerProductConfiguratorEvent = new CustomEvent('close-product-configurator', {
                detail: {
                    productConfigId: this.productConfigId
                }
            });
            document.querySelector('[data-product-configurator]').dispatchEvent(triggerProductConfiguratorEvent);
        },
        /**
         * Each product config has it's unique id set the id for this current configuration
         */
        setProductConfigId(id) {
            this.productConfigId = id;
        },
        /**
         * Set the title, description & icon for the current step
         * @param header
         */
        updateHeader(header) {
            this.title = header.title;
            this.description = header.description;
            this.icon = header.icon;
        },
        /**
         * Set the progressTabs
         * @param tabs
         */
        updateProgressTabs(tabs) {
            this.progressTabs = tabs;
        },
        /**
         * Move on to the next step
         */
        nextStep() {
            const nextIndex = this.steps.indexOf(this.activeStep) + 1;
            this.activeStep = this.steps[nextIndex];
        },
        /**
         * Move back to the previous step
         */
        previousStep() {
            const previousIndex = this.steps.indexOf(this.activeStep) - 1;
            this.activeStep = this.steps[previousIndex];
        },
        /**
         * Move back to the previous step
         */
        goToStep({step, stepSettings}) {
            // Go tho the given step
            this.activeStep = this.steps[this.steps.indexOf(step)]

            // We can pass aditional info to a step via the stepSettings
            // if given we will set it
            if(stepSettings) {
                this.stepSettings = stepSettings;
            }
        },
        /**
         * We clear the stepSettings
         */
        clearstepSettings() {
            this.stepSettings = null;
        },
        /**
         * Change the loading status
         * @param status
         */
        changeLoading(status) {
            this.loading = status;
        }
    },
    mounted() {
        // Add listeners for normal start triggers
        document.querySelectorAll('[data-product-configurator-start]').forEach($el => $el.addEventListener('click', this.startNewConfiugrator));
        document.querySelectorAll('[href^="#product-configurator-start"]').forEach($el => $el.addEventListener('click', this.startConfiugrator));

        // Add event listeners for starting the configurator with preset values
        document.querySelector('[data-product-configurator]').addEventListener('trigger-product-configurator', this.startConfiugrator);
    }
}
</script>
