<template>
    <div
        class="plate-background"
        :class="{'plate-background--grain': material?.hasGrainDirection && material.grainIsHorizontal, 'plate-background--grain-vertical': material?.hasGrainDirection && !material.grainIsHorizontal}"
    >
        <v-stage
            v-if="stageConfig"
            ref="stage"
            :config="stageConfig"
        >
            <v-layer>
                <v-rect
                    v-for="(shape, key) in shapes"
                    :key="key"
                    :config="shape"
                />
            </v-layer>
        </v-stage>
    </div>
</template>

<script>
    export default {
        name: 'BinPackingVisualisation',
        data() {
            return {
                container: null,
            }
        },
        props: {
            bin: {
                type: Object
            },
            material: {
                type: Object
            }
        },
        computed: {
            /**
             * We calculate the scale factor depending on the parent container element and the bin width
             * @returns {number}
             */
            scaleFactor() {
                if (this.container) {
                    return this.container.offsetWidth / this.bin.width;
                }

                return 0;
            },
            /**
             * Return the config needed for the stage
             * @returns {{width: number, height: number}}
             */
            stageConfig() {
                return {
                    width: Math.ceil(this.bin.width * this.scaleFactor),
                    height: Math.ceil(this.bin.height * this.scaleFactor)
                }
            },
            /**
             * Translate the bin shapes to shapeConfig for Konva to use
             * @returns {*}
             */
            shapes() {
                return this.bin.usedShapes.map(shape => {
                    if (shape.width && shape.height && this.scaleFactor) {

                        return {
                            x: Math.floor(shape.xPos * this.scaleFactor),
                            y: Math.floor(shape.yPos * this.scaleFactor),
                            width: Math.floor(shape.width * this.scaleFactor),
                            height: Math.floor(shape.height * this.scaleFactor),
                            cornerRadius: shape.data?.radius ? Math.floor(shape.data.radius * this.scaleFactor) : null,
                            fill: '#E8E2D9',
                            opacity: .7,
                            stroke: '#1E274B',
                            strokeWidth: 1,
                        }
                    }
                })
            }
        },
        mounted() {
            this.container = this.$el;
            this.$emit('mounted');
        }
    }
</script>
