<template>
    <div class="thanks">
        <transition name="slide-up">
            <div v-if="stepData">
                <h2 class="thanks__title">
                    <i class="icon-check-2 thanks__icon"></i>
                    <div>
                        <strong>Bedankt,</strong> uw panelen zijn toegevoegd aan de bestelling
                    </div>
                </h2>
                <div class="tile tile--no-shadow tile--sm tile--color-1 reading">
                    <h2 class="tile__title"><strong>Klaar met bestellen?</strong></h2>
                    <p>
                        Ga direct door naar het winkelmandje om veilig online af te rekenen en uw bestelling <strong>{{ translations.deliveryStatusLabel }}</strong> te hebben.
                    </p>
                    <a :href="cartHref" class="button button--full" @click="forceClose">
                        <i class="icon-cart"></i>
                        Bekijk en bewerk mijn bestelling
                    </a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import StepMixin from "@/mixins/StepMixin";

export default {
    name: 'ThanksStep',
    mixins: [StepMixin],
    data() {
        return {
            cartHref: cartHref,
            translations: window.translations,
            stepUrl: baseHref + '/configurator/ajax/thanks',
        }
    }
}
</script>
