<template>
    <div v-if="panel === activePanel">
        <form-group
            label="Titel"
            :errorMessage="getValidationError('title')"
        >
            <input
                v-model="panel.title"
                :disabled="disabled"
                @blur="validate('title')"
                type="text"
                class="form__control"
                id="title"
            >
        </form-group>

        <transition name="slide-up">
            <form-group
                v-if="shapeOptions"
                label="Kies een zaagvorm"
                :errorMessage="getValidationError('materialShapeId')"
            >
                <template v-slot:label>
                    <label class="form__label d-flex flex-wrap justify-content-between">
                        <span>Kies een zaagvorm</span> <span class="text--color-1 text--sm">Complexere zaagvorm nodig? <a :href="contactHref" target="_blank">Neem contact op.</a></span>
                    </label>
                </template>
                <select-with-image
                        v-model="panel.materialShapeId"
                        @update:modelValue="validate('materialShapeId')"
                        :options="shapeOptions"
                        :imagePlaceholderRounded="false"
                        :disabled="disabled"
                        id="shape"
                />
            </form-group>
        </transition>
        <div class="row">
            <div class="col-md-6">
                <transition-group name="slide-up">
                    <form-group
                        v-for="propertyValue in panel.shapePropertyValues"
                        :key="propertyValue.propertyId"
                        :label="getPropertyTitle(propertyValue.propertyId, panel)"
                        :errorMessage="getValidationError(propertyValue.propertyId)"
                    >
                        <input
                            v-model="propertyValue.value"
                            :disabled="disabled"
                            @blur="validate(propertyValue.propertyId)"
                            type="number"
                            class="form__control"
                        >
                    </form-group>
                </transition-group>

                <form-group
                    label="Aantal"
                    :errorMessage="getValidationError('amount')"
                >
                    <input
                        v-model="panel.amount"
                        :disabled="disabled"
                        @blur="validate('amount')"
                        type="number"
                        class="form__control"
                    >
                </form-group>

                <div class="form__group" v-if="stepData.productConfig.material.hasGrainDirection">
                    <label class="form__checkbox form__checkbox--single">
                        <input type="checkbox" v-model="panel.grainDirection"
                               :disabled="disabled"
                               @change="validate('grainDirection')">
                        <span class="checkmark checkmark--checkbox"></span>
                        <span>Hou rekening met nerfrichting</span>
                    </label>
                    <div class="form__description m-t-xs-0">
                        <tooltip
                            :body="stepData.form.grainDirectionInfo.body"
                            direction="bottom"
                        >
                            <a href="#" @click.prevent.stop>Wat is nerfrichting?</a>
                        </tooltip>
                    </div>
                </div>

                <div class="form__group" v-if="stepData.productConfig.material.showSawCleanOption">
                    <label class="form__checkbox form__checkbox--single">
                        <input type="checkbox" v-model="panel.sawClean"
                               :disabled="disabled"
                               @change="validate('sawClean')">
                        <span class="checkmark checkmark--checkbox"></span>
                        <span>schoonzagen?</span>
                    </label>
                    <div class="form__description m-t-xs-0">
                        <tooltip
                            :body="stepData.form.sawCleanInfo.body"
                            direction="bottom"
                        >
                            <a href="#" @click.prevent.stop>Wat is schoonzagen?</a>
                        </tooltip>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <transition name="slide-up">
                    <div class="form__group" v-if="getShape(panel.materialShapeId) && getShape(panel.materialShapeId).legendImageHref">
                        <label class="form__label">
                            Uw gekoze zaagvorm
                        </label>
                        <img :src="getShape(panel.materialShapeId).legendImageHref" :alt="getShape(panel.materialShapeId).title">
                    </div>
                </transition>
                <transition name="slide-up">
                    <plate-slider
                        v-if="stepData.productConfig.binPacking && stepData.productConfig.binPacking.binCount"
                        :bins="stepData.productConfig.binPacking.bins"
                        :step-data="stepData"
                    />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import SelectWithImage from "@/components/form/controls/SelectWithImage";
import PlateSlider from "@/components/steps/partials/PlateSlider";
import Tooltip from "@/components/layout/Tooltip";
import FormGroup from "@/components/form/FormGroup";

export default {
    name: 'PanelForm',
    components: {
        FormGroup,
        SelectWithImage,
        PlateSlider,
        Tooltip,
    },
    data() {
        return {
            shape: false,
            localPanel: null,
            contactHref: contactHref,
            shapeHasBeenSet: false,
        }
    },
    props: {
        panel: {
            type: Object,
        },
        stepData: {
            type: Object,
        },
        activePanel: {
            type: Object,
        },
        validationErrors: {
            type: Object,
        },
        disabled: {
            type: Boolean
        }
    },
    computed: {
        /**
         * Posible options for shapes with the correct format for the select with images
         * @returns {*}
         */
        shapeOptions() {
            return this.stepData?.form?.shapes.map(shape => {
                return {
                    id: shape.id,
                    title: shape.title,
                    image: shape.iconImageHref
                }
            })
        },
    },
    watch: {
        localPanel: {
            handler(localPanel) {
                this.$emit('update:panel', localPanel);
            },
            deep: true
        },
        "localPanel.materialShapeId": {
            handler(newValue, oldValue) {
                // if there are shapePropertyValues and the shape changes we update the shapeProperties for the new shape
                // Only if the initalData has been set otherwise we will overwrite the shapeProperties if there are any panels set
                if (newValue !== oldValue && (this.initialDataSet || !Object.keys(this.localPanel.shapePropertyValues).length)) {
                    this.setShapeProperties(this.localPanel);
                }
            },
        },
        panel(panel) {
            if (panel !== this.localPanel) {
                console.log('hier');
                this.localPanel = panel;
            }
        }
    },
    methods: {
        getShape(shapeId) {
            return this.stepData.form.shapes.find(shape => shape.id === shapeId);
        },
        setShapeProperties(panel) {
            panel.shapePropertyValues = [];
            const shape = this.getShape(panel.materialShapeId);

            // if no shape can be found we stop here
            if ( ! shape) {
                return;
            }

            shape.properties.forEach(property => {
                panel.shapePropertyValues.push({
                    propertyId: property.id,
                    value: null
                })
            });
        },
        getPropertyTitle(propertyId, panel) {
            if ( ! panel.materialShapeId) {
                return null;
            }

            const shape = this.getShape(panel.materialShapeId);
            return shape.properties.find(property => property.id === propertyId).title;
        },
        /**
         * @param fieldId
         */
        validate(fieldId) {
            this.$emit('validate',{panelId: this.panel.internalId, fieldId: fieldId})
        },
        /**
         * Get the validaiton message for the field of the current panel
         * @param fieldId
         * @returns {*|null}
         */
        getValidationError(fieldId) {
            return this.validationErrors ?
                    this.validationErrors[fieldId]:
                    null;
        }
    },
    created() {
        this.localPanel = this.panel;
        this.initialDataSet = true;
    }
}
</script>
