<template>
    <div class="plate-slider"
         :class="{'plate-slider--initialized': slidesReady}"
    >
        <div class="plate-slider__slides">
            <div
                v-for="(bin, key) in bins"
                :key="key"
                :class="{
                            'plate-slider__slide--active': key === activeKey,
                            'plate-slider__slide--prev-animation': prevAnimation,
                            'plate-slider__slide--next-animation': nextAnimation
                        }"
                class="plate-slider__slide"
            >
                <bin-packing-visualisation
                    :bin="bin"
                    :material="material"
                    @mounted="pushBinPackingReady(key)"
                />
            </div>
        </div>
        <div class="plate-slider__navigation">
            <a href=""
               :class="{'plate-slider__navigation-button--disabled': prevDisabled}"
               @click.prevent.stop="prevSlide"
               class="plate-slider__navigation-button"
            >
                <i class="icon-arrow-left"></i>
            </a>
            <div class="plate-slider__navigation-label">
                Plaat {{activeKey + 1}}/{{this.bins.length}} <span class="muted">({{navigationLabel}})</span>
            </div>
            <a href=""
               :class="{'plate-slider__navigation-button--disabled': nextDisabled}"
               @click.prevent.stop="nextSlide"
               class="plate-slider__navigation-button"
            >
                <i class="icon-arrow-right"></i>
            </a>
        </div>
    </div>
</template>

<script>
import BinPackingVisualisation from "@/components/steps/partials/BinPackingVisualisation";

export default {
    name: 'PlateSliderCanvas',
    components: {
        BinPackingVisualisation,
    },
    data() {
        return {
            activeKey: 0,
            prevAnimation: false,
            nextAnimation: false,
            binPackingReady: []
        }
    },
    props: {
        bins: {
            type: Array
        },
        material: {
            type: Object,
        },
    },
    computed: {
        navigationLabel() {
            return this.bins[this.activeKey].width + 'x' + this.bins[this.activeKey].height + 'mm';
        },
        prevDisabled() {
            return this.activeKey === 0;
        },
        nextDisabled() {
            return this.activeKey === (this.bins.length - 1);
        },
        // when all binpacking visualisations are ready we will show the slider
        slidesReady() {
            return this.binPackingReady.length >= this.bins.length
        }
    },
    methods: {
        prevSlide() {
            let nextKey = this.activeKey - 1;
            this.prevAnimation = true;
            this.nextAnimation = false;

            if(nextKey >= 0) {
                this.activeKey = nextKey;
            }
        },
        nextSlide() {
            let nextKey = this.activeKey + 1;
            this.nextAnimation = true;
            this.prevAnimation = false;

            if(nextKey <= (this.bins.length - 1)) {
                this.activeKey = nextKey;
            }
        },
        pushBinPackingReady(key) {
            this.binPackingReady.push(key);
        }
    },
    watch: {
        bins() {
            if (this.bins.length < (this.activeKey + 1)) {
                this.activeKey = this.bins.length - 1;
            }
        }
    }
}
</script>
