<template>
    <step-wrapper
            :priceData="priceData"
            :showNextButton="! disabled"
            @triggerPreviousStep="previousStep"
            @submit="submit"
    >
        <transition name="slide-up">
            <div v-if="stepData">
                <div v-if="! disabled" class="tile tile--sm tile--no-shadow tile--bg reading m-b-xs-20">
                    <h2>Nog meer panelen zagen?</h2>
                    <p>
                        De plaat die u geselecteerd heeft, is voor <strong>{{usedSpace}}% gebruikt.</strong> U heeft nog <strong>{{freeSpace}}% hout over</strong> om panelen uit te zagen.
                    </p>
                    <a href="#" class="button button--ghost button--full" @click.prevent.stop="addPanels">
                        <i class="icon-zagen"></i>
                        Voeg nog meer panelen toe
                    </a>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <form-group
                            v-for="(restMaterial, key) in restMaterials"
                            :label="restMaterial.label"
                            :error-message="activeErrors['restMaterial-' + restMaterial.id]"
                        >
                            <template v-slot:label>
                                <label class="form__label">
                                    {{restMaterial.label}}

                                    <tooltip
                                        v-if="key === 0"
                                        :body="stepData.form.restMaterialInfo.body"
                                        direction="bottom"
                                    >
                                        <a href="#" class="m-b-xs-5" @click.prevent.stop>meer info</a>
                                    </tooltip>
                                </label>
                            </template>
                            <badge-control
                                v-model="restMaterial.checked"
                                :options="yesNoOptions"
                                :disabled="disabled"
                            />
<!--                            <div class="form__checkboxes">-->
<!--                                <label-->
<!--                                    v-for="restMaterial in stepData.form.restMaterial"-->
<!--                                    class="form__checkbox form__checkbox"-->
<!--                                >-->
<!--                                    <input type="checkbox" :value="restMaterial.value" v-model="restMaterials" :disabled="disabled">-->
<!--                                    <span class="checkmark checkmark&#45;&#45;checkbox"></span>-->
<!--                                    <span>{{ restMaterial.label }}</span>-->
<!--                                </label>-->
<!--                                <div class="form__description">-->
<!--                                    <tooltip-->
<!--                                        :body="stepData.form.restMaterialInfo.body"-->
<!--                                        direction="bottom"-->
<!--                                    >-->
<!--                                        <a href="#" @click.prevent.stop>-->
<!--                                            Wat is dit?-->
<!--                                        </a>-->
<!--                                    </tooltip>-->
<!--                                </div>-->
<!--                            </div>-->
                        </form-group>
                    </div>
                    <div class="col-md-6">
                        <plate-slider
                            v-if="stepData.productConfig.binPacking && stepData.productConfig.binPacking.binCount"
                            :bins="stepData.productConfig.binPacking.bins"
                            :step-data="stepData"
                        />
                    </div>
                </div>
                <div v-if="! disabled" class="tile tile--sm tile--no-shadow tile--bg tile--radius-sm d-flex flex-wrap flex-sm-nowrap align-items-center reading m-b-xs-40">
                    <h3 class="m-b-xs-0 m-r-xs-40">Boor- of freeswerk nodig voor dit paneel?</h3>
                    <a :href="contactHref" target="_blank" class="button button--ghost flex-shrink-0 m-t-xs-15 m-t-sm-0">
                        Neem contact op
                    </a>
                </div>
            </div>
        </transition>
    </step-wrapper>
</template>

<script>
import StepMixin  from "@/mixins/StepMixin";
import StepWrapper from "@/components/layout/StepWrapper";
import PlateSlider from "@/components/steps/partials/PlateSlider";
import Tooltip from "@/components/layout/Tooltip";
import BadgeControl from "@/components/form/controls/BadgeControl";

export default {
    name: 'OrderStep',
    mixins: [StepMixin],
    components: {
        StepWrapper,
        PlateSlider,
        Tooltip,
        BadgeControl,
    },
    data() {
        return {
            stepUrl: baseHref + '/configurator/ajax/order',
            restMaterials: [],
            freeSpace: null,
            usedSpace: null,
            contactHref: contactHref,
            yesNoOptions: [
                {
                    value: true,
                    label: 'Ja',
                },
                {
                    value: false,
                    label: 'Nee',
                }
            ]
        }
    },
    computed: {
        formData() {
            console.log()
            return {restMaterials: this.restMaterials}
        }
    },
    watch: {
        /**
         * When the stepData is updated we will check if we have any default data for ower panels
         */
        stepData() {
            this.restMaterials = this.stepData.form.restMaterial;
            this.freeSpace = this.stepData.productConfig.binPacking.freeSpace;
            this.usedSpace = this.stepData.productConfig.binPacking.usedSpace;
        }
    },
    methods: {
        addPanels() {
            this.$emit('goToStep',{step: 'PanelsStep', stepSettings: {newPanel: true}})
        },
        /**
         * Notify the parent component that we are ready to go to the next step
         * if we come in the nextStep function of the this component this means submit was succesfull and we also notify that an item has been added to the cart
         */
        nextStep() {
            this.notifyItemAddedToCart();
            this.$emit('nextStep');
        },
        /**
         * Trigger event on the configurator to let other components know we have added an item to the cart
         */
        notifyItemAddedToCart() {
            const triggerProductConfiguratorEvent = new CustomEvent('cart-item-added', {
                detail: {
                    productConfigId: this.productConfigId
                }
            });
            document.querySelector('[data-product-configurator]').dispatchEvent(triggerProductConfiguratorEvent);
        }
    }
}
</script>

