<template>
    <form @submit.prevent="submit" class="side-bar__form">
        <slot></slot>

        <div class="side-bar__bottom">
            <transition name="fade">
                <div class="side-bar__price-wrapper" v-if="priceData && priceData.totalPrice">
                    <div class="side-bar__price">
                        <strong>{{ priceData.totalPrice }}</strong> (incl. BTW)
                    </div>
                    <div class="side-bar__delivery">
                        <span class="side-bar__delivery-status" v-if="priceData.supply">
                            {{ translations.deliveryStatusLabel }}
<!--                            <strong>-->
<!--                                <span v-if="priceData.supply.status === 'in-stock'" class="icon-check-success"><span class="path1"></span><span class="path2"></span><span-->
<!--                                        class="path3"></span></span>-->
<!--                                <span v-if="priceData.supply.status === 'almost-out-of-stock'" class="icon-check-success"><span class="path1"></span><span class="path2"></span><span-->
<!--                                        class="path3"></span></span>-->
<!--                                <span v-if="priceData.supply.status === 'not-stock'" class="icon-check-success"><span class="path1"></span><span class="path2"></span><span-->
<!--                                        class="path3"></span></span>-->
<!--                                {{priceData.supply.LabelStatus}}-->
<!--                            </strong> - {{priceData.supply.labelDeliveryTime}}-->
                        </span>
                    </div>
                </div>
            </transition>
            <div class="side-bar__buttons">
                <div @click.prevent="triggerPreviousStep" class="button button--big button--center button--ghost side-bar__button">
                    <i class="icon-arrow-left"></i>
                    Stap terug
                </div>
                <div v-if="showNextButton" @click.prevent="submit" class="button button--big button--center side-bar__button button--icon-last">
                    Volgende stap
                    <i class="icon-arrow-right"></i>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import Loader from './Loader';

export default {
    name: 'StepWrapper',
    data() {
        return {
            translations: window.translations,
        }
    },
    props: {
        priceData: {
            type: Object
        },
        loading: {
            type: Boolean
        },
        showNextButton: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        /**
         * Notify the parent component that we are ready to go to the previous step
         */
        triggerPreviousStep(e) {
            // stop the panel from closing
            e.stopPropagation();
            this.$emit('previousStep');
        },
        /**
         * Notify the parent component that we are ready to go to the next step
         */
        submit(e) {
            // stop the panel from closing
            e.stopPropagation();
            this.$emit('submit');
        },
    }
}
</script>
