<template>
    <div>
        <transition name="fade">
            <div v-if="show" class="modal-overlay" @click="cancel"></div>
        </transition>
        <transition name="slide-up-small">
            <div v-if="show" class="modal reading">
                <h3>{{ translations.confirmationTitle }}</h3>
                <p>
                    {{ translations.confirmationBody }}
                </p>
                <div class="button-group d-flex justify-content-end">
                    <a href="#" class="button button--link" @click.prevent="cancel">{{ translations.confirmationCancelLabel }}</a>
                    <a href="#" class="button" @click.prevent="close">{{ translations.confirmationConfirmLabel }}</a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ConfirmationModal',
    data() {
        return {
            translations: window.translations,
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'show',
        event: 'update:show'
    },
    methods: {
        close() {
            this.$emit('update:show', false);
            this.$emit('close');
        },
        cancel() {
            this.$emit('update:show', false);
        }
    }
}
</script>
