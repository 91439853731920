<template>
    <step-wrapper
            :priceData="priceData"
            @triggerPreviousStep="previousStep"
            @submit="submit"
    >
        <transition name="slide-up">
            <div v-if="stepData">
                <panel-navigation
                        :panels="panels"
                        :activePanel="activePanel"
                        :canCreateNew="false"
                        :canDelete="false"
                        @editPanel="editPanel"
                />
                <sides-form
                    v-for="(currentPanel, key) in panels"
                    :key="currentPanel.internalId"
                    v-model:panel="panels[key]"
                    :activePanel="activePanel"
                    :stepData="stepData"
                    :disabled="disabled"
                    @update:panel="panelUpdate"
                />
            </div>
        </transition>
    </step-wrapper>
</template>

<script>
import StepMixin  from "@/mixins/StepMixin";
import StepWrapper from "@/components/layout/StepWrapper";
import PanelNavigation from "@/components/steps/partials/PanelNavigation";
import SidesForm from "@/components/steps/partials/SidesForm";

export default {
    name: 'SidesStep',
    mixins: [StepMixin],
    components: {
        SidesForm,
        PanelNavigation,
        StepWrapper,
    },
    data() {
        return {
            stepUrl: baseHref + '/configurator/ajax/sides',
            panels: [],
            activePanel: null,
            priceTimeout: null,
        }
    },
    computed: {
        formData() {
            return {panels: this.panels}
        }
    },
    watch: {
        /**
         * When the stepData is updated we will check if we have any default data for ower panels
         */
        stepData() {
            // if there are any panels in the product config we will use them
            if (this.stepData.productConfig.panels.length) {
                this.panels = this.stepData.productConfig.panels
                // set the first panel as active
                this.activePanel = this.panels[0];
            }
        }
    },
    methods: {
        editPanel(panel) {
            this.activePanel = panel;
        },
        panelUpdate() {
            // add a Timeout so we don't spam the backend
            clearTimeout(this.priceTimeout);
            this.priceTimeout = setTimeout(this.updatePrice, 500);
        }
    }
}
</script>

