<template>
    <div class="loader loader--center"
        :class="{'loader--loading': loading}"
    >
        <svg class="loader__icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 35.7037392 30.5185013"
             style="enable-background:new 0 0 35.7037392 30.5185013;" xml:space="preserve">

                <g class="loader__icon-saw">
                    <path class="loader__icon-saw" d="M17.7518444,17.52775c1.2528992,0,2.2684994-1.0156002,2.2684994-2.2684994
                    c0-1.2529011-1.0156002-2.2685013-2.2684994-2.2685013s-2.2685013,1.0156002-2.2685013,2.2685013
                    C15.4833431,16.5121498,16.4989452,17.52775,17.7518444,17.52775z"/>
                                <path class="st0" d="M17.7528191,23.0375175c4.2959595,0,7.778265-3.4823055,7.778265-7.778266
                    s-3.4823055-7.7782698-7.778265-7.7782698s-7.7782698,3.4823055-7.7782698,7.7782698S13.4568586,23.0375175,17.7528191,23.0375175z
                    "/>
                    <g>
                        <path class="st0" d="M32.0111427,15.2592506l-2.9166985-2.2555008l1.8278008-3.2019005l-3.5519009-0.959259l0.4666996-3.6685205
                            l-3.668499,0.46667l-0.959301-3.5518498l-3.2017994,1.8277698L17.7518444,1l-2.2555008,2.9166598l-3.2019005-1.8277698
                            l-0.9721994,3.5518498l-3.65557-0.46667l0.4666595,3.6685205l-3.5518498,0.959259l1.8277802,3.2019005l-2.9166698,2.2555008"/>
                                        <path class="st0" d="M3.4925938,15.2592506l2.9167004,2.2554989L4.5814934,20.71665l3.5519009,0.9592609l-0.4666996,3.66852
                            l3.668499-0.466671l0.959301,3.5518513l3.2017994-1.8277702l2.2556,2.9166603l2.2554989-2.9166603l3.2019005,1.8277702
                            l0.9722004-3.5518513l3.6555691,0.466671l-0.4666595-3.66852l3.5518513-0.9592609l-1.8277817-3.2019005l2.9166698-2.2554989"/>
                    </g>
                </g>
                <path class="loader__icon-piece" d="M34.7037392,19.7962513H1v3.8888988h33.7037392V19.7962513z"/>

        </svg>

    </div>
</template>

<script>
export default {
    name: "loader",
    props: {
        loading: {
            type: Boolean
        }
    }
}
</script>