<template>
    <div class="tips" v-if="stepData">
        <div class="tips__intro" v-if="stepData.text">
            <img
                :src="stepData.text.image"
                :alt="stepData.text.title"
                class="tips__intro-img"
            >
            <div
                class="tips__intro-text reading"
                v-html="stepData.text.body"
            >
            </div>
        </div>
        <div class="tips__list">
            <div
                v-for="(tip, key) in stepData.text.children"
                :key="key"
                class="tips__list-item"
            >
                <img
                    :src="tip.image"
                    :alt="tip.title"
                    class="tips__list-img"
                >
                <div
                    class="tips__list-text reading"
                    v-html="tip.body"
                >
                </div>
            </div>
        </div>
        <div class="tips__buttons button-group">
            <a href="#"
               @click.stop.prevent="nextStep"
               class="button button--big">
                <i class="icon-zagen"></i>
                Starten met op maat zagen
            </a>
            <a href="#"
                @click.stop.prevent="close"
               class="button button--link">
                Annuleren
            </a>
        </div>
    </div>
</template>

<script>
import StepMixin  from "@/mixins/StepMixin";

export default {
    name: 'TipsStep',
    mixins: [StepMixin],
    data() {
        return {
            stepUrl: baseHref + '/configurator/ajax/tips',
        }
    },
    methods: {
    },
    created() {
        this.fetchStep();
    }
}
</script>
