const StepMixin =  {
    data() {
        return {
            stepData: null,
            priceData: null,
            header: null,
            abortController: null,
            loading: false,
            activeErrors: {},
            abortControllerSubmit: null
        }
    },
    props: {
        productConfigId: {
            type: [String, Number],
        },
        stepSettings: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        loading(value) {
            this.$emit('changeLoading', value);
        }
    },
    methods: {
        async updatePrice() {
            if(this.abortController) {
                this.abortController.abort();
            }
            this.abortController = new AbortController();

            const params = this.productConfigId ?
                    {productConfigId: this.productConfigId, ...this.formData} :
                    this.formData;

            const result = await this.$get(this.stepUrl, params, this.abortController.signal);

            this.abortController = null;

            if(result && result.success) {
                this.setPriceData(result.data);
            }
        },
        /**
         * Fetch the data needed for the current step
         * @returns {Promise<void>}
         */
        async fetchStep() {
            if(this.abortController) {
                this.abortController.abort();
            }
            this.abortController = new AbortController();

            const params = this.productConfigId ?
                    {productConfigId: this.productConfigId} :
                    null;

            this.loading = true;
            const result = await this.$get(this.stepUrl, params, this.abortController.signal);
            this.loading = false;
            this.abortController = null;

            if(result && result.success) {
                this.stepData = result.data;
                this.setPriceData(result.data);
                this.$emit('updateHeader', this.stepData.header);
                this.$emit('updateProgressTabs', this.stepData.tabs);
            }
        },
        /**
         * Submit the data to the backend
         * @returns {Promise<void>}
         */
        async submit() {
            // if we are in disabled mode we move on to the next step without any actions
            if (this.disabled) {
                this.nextStep();
                return;
            }

            if(this.abortControllerSubmit) {
                this.abortControllerSubmit.abort();
            }

            this.abortControllerSubmit = new AbortController();
            const params = this.productConfigId ?
                    {productConfigId: this.productConfigId} :
                    null;

            this.loading = true;
            const result = await this.$post(this.stepUrl, this.formData, params, this.abortControllerSubmit.signal);
            this.loading = false;
            this.abortControllerSubmit = null;

            if (result && result.success) {
                if(result.data.productConfig.id) {
                    this.$emit('setProductConfigId', result.data.productConfig.id);
                }

                this.nextStep();
            } else if(result && ! result.success) {
                this.stepData.form.validationErrors = result.data.form.validationErrors;
                this.stepData.productConfig.binPacking = result.data.productConfig.binPacking;
                this.setAllValidationMessages();
            }
        },
        async validate() {
            const params = this.productConfigId ?
                    {productConfigId: this.productConfigId, validation: true} :
                    null;

            const result = await this.$post(this.stepUrl, this.formData, params);

            if(result) {
                this.stepData.form.validationErrors = result.data.form.validationErrors;
                this.stepData.productConfig.binPacking = result.data.productConfig.binPacking;
            }

            return result;
        },
        /**
         * Notify the parent component that we are ready to go to the previous step
         */
        previousStep() {
            this.$emit('previousStep');
        },
        /**
         * Notify the parent component that we are ready to go to the next step
         */
        nextStep() {
            this.$emit('nextStep');
        },
        /**
         * Notify the parent component that we wan't to stop with this configuration
         */
        close() {
            this.$emit('close');
        },
        /**
         * We translate the productCOnfig data to priceData so it is easier for us to handle
         * @param data
         */
        setPriceData(data) {
            if ( ! data.productConfig) {
                return;
            }

            this.priceData = {
                totalPrice : data.productConfig.totalPrice,
                materialPrice : data.productConfig.material? data.productConfig.material.price : undefined,
                pricePerSquareMeter : data.productConfig.material? data.productConfig.material.pricePerSquareMeter : undefined,
                supply : data.productConfig.supply
            }
        },
        /**
         * Set all error messages in step data as active errors
         */
        setAllValidationMessages() {
            this.stepData.form.validationErrors.forEach(error => {
                this.activeErrors[error.id] = error.errorMessage;
            })
        },
    },
    created() {
        this.fetchStep();
    }
}

export default StepMixin;