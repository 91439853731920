<template>
    <div class="form__group">
        <label class="form__label">
            Mijn platen
            <tooltip
                :body="stepData.form.myPlatesInfo.body"
                direction="top"
            >
                <a href="#" @click.prevent.stop>meer info</a>
            </tooltip>
        </label>

        <plate-slider-canvas :bins="bins" :material="stepData.productConfig.material" />
    </div>
</template>

<script>
import Tooltip from "@/components/layout/Tooltip";
import PlateSliderCanvas from "@/components/steps/partials/PlateSliderCanvas";

export default {
    name: 'PlateSlider',
    components: {
        PlateSliderCanvas,
        Tooltip
    },
    props: {
        bins: {
            type: Array
        },
        stepData: {
            type: Object
        }
    },
}
</script>
