import { createApp } from 'vue'
import App from './App.vue'
import dataManager from './utils/data-manager';
import vSelect from 'vue-select';
import VueKonva from 'vue-konva';

// Global Components
import FormGroup from "@/components/form/FormGroup";

const app = createApp(App);

// Local Plugins
app.use(dataManager);

// Plugins
app.component('v-select', vSelect)
app.component('form-group', FormGroup)

app.mount('[data-product-configurator]');
app.use(VueKonva);

// Local directives
app.directive('click-outside', {
    mounted(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('mousedown', el.clickOutsideEvent);
    },
    deactivated(el) {
        document.body.removeEventListener('mousedown', el.clickOutsideEvent);
    }
});

app.directive('focus', {
    mounted: (el) => el.focus()
});