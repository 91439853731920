<template>
    <step-wrapper
        :priceData="priceData"
        :loading="loading"
        @triggerPreviousStep="previousStep"
        @submit="submit"
    >
        <transition name="slide-up">
            <div v-if="stepData">
                <form-group
                    v-if="parentMaterialOptions"
                    label="Kies de materiaal categorie"
                    :error-message="activeErrors['categoryId']"
                >
                    <select-with-image
                            v-model="selectedCategory"
                            :options="categoryOptions"
                            :disabled="disabled"
                            @update:modelValue="materialCategorySelected"
                            id="categoryMaterial"
                    />
                </form-group>
                <transition name="slide-up">
                    <form-group
                        v-if="parentMaterialOptions && selectedCategory"
                        label="Kies het materiaal"
                        :error-message="activeErrors['materialId']"
                    >
                        <select-with-image
                                v-model="selectedParentMaterial"
                                :options="parentMaterialOptions"
                                :disabled="disabled"
                                @update:modelValue="materialSelected()"
                                id="parentMaterial"
                        />
                    </form-group>
                </transition>
                <transition name="slide-up">
                    <form-group
                        v-if="childMaterialOptions && childMaterialOptions.length"
                        label="Kies de uitvoering"
                        :error-message="activeErrors['materialId']"
                    >
                        <select-with-image
                                v-model="selectedChildMaterial"
                                :options="childMaterialOptions"
                                :disabled="disabled"
                                @update:modelValue="materialSelected()"
                                id="childMaterial"
                        />
                    </form-group>
                </transition>
                <transition name="slide-up">
                    <form-group
                        v-if="selectedMaterial && selectedMaterial.fsc && fscOptions && fscOptions.length"
                        label="Materiaal met het FSC label"
                        :error-message="activeErrors['fsc']"
                    >
                        <template v-slot:label>
                            <label class="form__label"  for="fsc">
                                Materiaal met het FSC label
                                <div :href="durabilityHref" target="_blank" class="form__label-link tooltip">
                                    <i class="icon-notification"></i>

                                    <div class="tooltip__body tooltip__body--right" v-html="translations.durabilityTooltip">
                                    </div>
                                </div>
                            </label>
                        </template>
                        <div class="form__badges badges">
                            <badge-control
                                v-model="fsc"
                                @update:modelValue="setValidationMessage('fsc')"
                                :options="fscOptions"
                                :disabled="disabled"
                            />
                        </div>
                    </form-group>
                </transition>
                <transition name="slide-up">
                    <form-group
                            v-if="selectedMaterial && selectedMaterial.pefc && pefcOptions && pefcOptions.length"
                            label="Materiaal met het PEFC label"
                            :error-message="activeErrors['pefc']"
                    >
                        <template v-slot:label>
                            <label class="form__label" for="pefc">
                                Materiaal met het PEFC label
                                <div class="form__label-link tooltip">
                                    <i class="icon-notification"></i>

                                    <div class="tooltip__body tooltip__body--right"  v-html="translations.durabilityTooltip">
                                    </div>
                                </div>
                            </label>
                        </template>
                        <div class="form__badges badges">
                            <badge-control
                                v-model="pefc"
                                @update:modelValue="setValidationMessage('pefc')"
                                :options="pefcOptions"
                                :disabled="disabled"
                            />
                        </div>
                    </form-group>
                </transition>
                <transition name="slide-up">
                    <form-group
                        v-if="thicknessOptions && thicknessOptions.length"
                        label="Kies de gewenste dikte"
                        :error-message="activeErrors['thickness']"
                    >
                        <badge-control
                            v-model="selectedThickness"
                            @update:modelValue="setValidationMessage('thickness')"
                            :options="thicknessOptions"
                            :disabled="disabled"
                        />
                    </form-group>
                </transition>
                <transition name="slide-up">
                    <form-group
                        v-if="surfaceOptions && surfaceOptions.length"
                        label="Kies de groote van uw plaat"
                        :error-message="activeErrors['surface']"
                    >
                        <badge-control
                            v-model="selectedSurface"
                            @update:modelValue="setValidationMessage('surface')"
                            :options="surfaceOptions"
                            :disabled="disabled"
                        />
                    </form-group>
                </transition>
            </div>
        </transition>
    </step-wrapper>
</template>

<script>
import StepMixin  from "@/mixins/StepMixin";
import StepWrapper from "@/components/layout/StepWrapper";
import SelectWithImage from "@/components/form/controls/SelectWithImage";
import BadgeControl from "@/components/form/controls/BadgeControl";
import FormGroup from "@/components/form/FormGroup";

export default {
    name: 'MaterialStep',
    mixins: [StepMixin],
    components: {
        FormGroup,
        BadgeControl,
        StepWrapper,
        SelectWithImage,
    },
    data() {
        return {
            durabilityHref: durabilityHref,
            stepUrl: baseHref + '/configurator/ajax/material',
            fsc: null,
            pefc: null,
            fscOptions: null,
            pefcOptions: null,
            selectedCategory: null,
            selectedParentMaterial: null,
            selectedChildMaterial: null,
            selectedThickness: null,
            selectedSurface: null,
            dimensionOptionsSet: false,
            translations: window.translations,
        }
    },
    computed: {
        /**
         * We get the selected material
         * We first check if there is a material selected at child level
         * if not we will check the parent
         * If the parent has no value we will not have any selected material
         * @returns {*}
         */
        selectedMaterial() {
            if ( ! this.stepData && ! this.selectedCategory) {
                return null;
            }

            const parentmaterial = this.stepData.form.materials
                    .find(item => item.id === this.selectedParentMaterial);
            let childMaterial = null;

            if ( ! parentmaterial) {
                this.selectedParentMaterial = null;
                return null;
            }

            if (this.selectedChildMaterial && this.selectedParentMaterial) {
                childMaterial = parentmaterial
                        .children.find(item => item.id === this.selectedChildMaterial)

                if ( ! childMaterial) {
                    this.selectedChildMaterial = null;
                    return null;
                }
            }

            return childMaterial ? childMaterial : parentmaterial;
        },
        /**
         * Options for material category
         * @returns {*}
         */
        categoryOptions() {
            return this.stepData?.form?.categories;
        },
        /**
         * Options for parent material
         * @returns {*}
         */
        parentMaterialOptions() {
            return this.stepData?.form?.materials;
        },
        /**
         * Options for child material
         * @returns {null|*}
         */
        childMaterialOptions() {
            if ( ! this.selectedParentMaterial) {
               return null;
            }
            return this.stepData.form.materials.find(item => item.id === this.selectedParentMaterial).children;
        },
        /**
         * Ge the thickness options
         * @returns {null|*}
         */
        thicknessOptions() {
            if ( ! this.selectedMaterial || ! this.stepData.form.thicknesses) {
               return null;
            }

            return this.stepData.form.thicknesses.map(thickness => {
                return {
                    value: thickness,
                    label: thickness  +' mm'
                }
            });
        },
        /**
         * Ge the surface options
         * @returns {null|*}
         */
        surfaceOptions() {
            if ( ! this.selectedMaterial || ! this.stepData.form.surfaces) {
               return null;
            }

            return Object.keys(this.stepData.form.surfaces).map(surface => {
                return {
                    value: surface,
                    label: surface
                }
            });
        },
        /**
         * Configurator is ready when we have a material, tichkness, surface and fscReady or pefcReady
         * @returns {null|*}
         */
        configuratorReady() {
            return this.selectedSurface && this.selectedThickness && this.selectedMaterial && this.fscReady && this.pefcReady;
        },
        fscReady() {
            return !this.selectedMaterial.fsc || (this.selectedMaterial.fsc  && this.fsc !== null);
        },
        pefcReady() {
            return !this.selectedMaterial.pefc  || (this.selectedMaterial.pefc && this.pefc !== null);
        },
        formData() {
            return {
                fsc: this.fsc,
                pefc: this.pefc,
                categoryId: this.selectedCategory ? this.selectedCategory : null,
                materialId: this.selectedMaterial ? this.selectedMaterial.id : null,
                surface: this.selectedSurface && this.selectedMaterial ? this.selectedMaterial.surfaces[this.selectedSurface] : null,
                thickness: this.selectedMaterial ? this.selectedThickness : null,
            }
        }
    },
    watch: {
        /**
         * When the stepData is updated we will check if we have any default data for ower material/thickness/surface
         */
        stepData() {
            // if the category in the product config is precent we set it
            if (this.stepData.productConfig.category) {
                this.selectedCategory = this.stepData.productConfig.category.id;
            }

            // if the material in the product config is a parent
            if (this.stepData.productConfig.material && this.materialIsParent(this.stepData.productConfig.material.id)) {
                this.selectedParentMaterial = this.stepData.productConfig.material.id;
                this.selectedChildMaterial = null;
            }

            // if the material in the product config is a child material
            if (this.stepData.productConfig.material && this.materialIsChild(this.stepData.productConfig.material.id)) {
                this.selectedParentMaterial = this.getParentMaterialId(this.stepData.productConfig.material.id);
                this.selectedChildMaterial = this.stepData.productConfig.material.id;
            }

            this.$nextTick(() => {
                this.dimensionOptionsSet = false;
                this.selectedThickness = this.stepData.productConfig.dimension ?
                        this.stepData.productConfig.dimension.thickness :
                        null;

                this.selectedSurface =  this.stepData.productConfig.dimension ?
                        this.stepData.productConfig.dimension.surface.height + 'x' + this.stepData.productConfig.dimension.surface.width:
                        null;

                if (this.stepData.productConfig.dimension) {
                    this.fsc = this.stepData.productConfig.dimension.fsc;
                    this.pefc = this.stepData.productConfig.dimension.pefc;
                }
            });
        },
        /**
         * Get updated price when some formData changes
         * @param newValue
         * @param oldValue
         */
        formData: {
            handler(newValue, oldValue) {
                if(newValue !== oldValue && this.selectedMaterial) {
                    this.fetchUpdatedDimensions();
                }
            },
            deep: true
        }
    },
    methods: {
        /**
         * Check if the materialId can be found as parent material
         * @param materialId
         * @returns {boolean}
         */
        materialIsParent(materialId) {
            return this.stepData.form.materials.filter(material => material.id === materialId).length !== 0;
        },
        /**
         * Check if the materialId can be found as a child material
         * @param materialId
         * @returns {boolean}
         */
        materialIsChild(materialId) {
            let isChild = false;

            this.stepData.form.materials.forEach(material => {
                if(material.children.find(childMaterial => childMaterial.id === materialId)) {
                    isChild = true;
                }
            });

            return isChild
        },
        /**
         * Get the parent materialId
         * @param childMaterialId
         * @returns {*}
         */
        getParentMaterialId(childMaterialId) {
            let out;
            this.stepData.form.materials.forEach(material => {
                if(material.children.find(childMaterial => childMaterial.id === childMaterialId)) {
                    out = material.id;
                }
            });

            return out;
        },
        /**
         * When the selectedMaterial changes we reset the selected values of thickness en surface
         */
        materialSelected() {
            this.setValidationMessage('materialId')

            this.fsc = null;
            this.pefc = null;
            this.selectedSurface = null;
            this.selectedSurface = null;
            this.selectedThickness = null;
        },
        /**
         * Trigger the validation + get the new material options for the selected category
         * @returns {Promise<void>}
         */
        async materialCategorySelected() {
            this.loading = true;
            const result = await this.setValidationMessage('categoryId');
            this.loading = false;

            // set the new material options now that we have the result for the current category
            this.stepData.form.materials = result.data.form.materials;
        },
        /**
         * We get the validation and only set the value of the field that has triggerd the validation
         * @param $event
         * @returns {Promise<void>}
         */
        async setValidationMessage(fieldId) {
            const result = await this.validate();

            const errors = this.stepData.form.validationErrors;
            if ( ! errors) {
                this.activeErrors[fieldId] = null;
                return result;
            }

            const error = errors.find(error => error.id === fieldId);

            if ( ! error) {
                this.activeErrors[fieldId] = null;
                return result;
            }

            this.activeErrors[fieldId] = error.errorMessage;
            return result;
        },
        /**
         * Get the updated dimensions for current settings and set the price if given
         * @returns {Promise<void>}
         */
        async fetchUpdatedDimensions() {
            if(this.abortController) {
                this.abortController.abort();
            }
            this.abortController = new AbortController();

            let params = {};
            if (this.formData.pefc !== null) {
                params.pefc = this.formData.pefc;
            }

            if (this.formData.fsc !== null) {
                params.fsc = this.formData.fsc;
            }

            if (this.formData.surface) {
                params.width = this.formData.surface.width;
                params.height = this.formData.surface.height;
            }

            if (this.formData.thickness) {
                params.thickness = this.selectedThickness;
            }

            this.loading = true;
            const result = await this.$get(
                    baseHref + '/configurator/ajax/material/get-material-dimensions/' + this.selectedMaterial.id,
                    params,
                    this.abortController.signal
            );
            this.loading = false;

            this.abortController = null;

            if(result && result.success) {
                // only set the updated options if thicknes and ord surface is unkown
                if (! this.configuratorReady || !this.dimensionOptionsSet) {
                    this.stepData.form.thicknesses = result.data.thicknesses;
                    this.stepData.form.surfaces = result.data.surfaces;
                    this.dimensionOptionsSet = true;
                }

                this.fscOptions = result.data.fsc.map(value => {
                    return {
                        value: value,
                        label: value ? 'Ja' : 'Nee',
                    }
                });
                this.pefcOptions = result.data.pefc.map(value => {
                    return {
                        value: value,
                        label: value ? 'Ja' : 'Nee',
                    }
                });

                if(result.data.fsc.length === 1 && !this.selectedMaterial.pefc) {
                    this.fsc = result.data.fsc[0];
                }

                if(result.data.pefc.length === 1 && !this.selectedMaterial.fsc) {
                    this.pefc = result.data.pefc[0];
                }

                // if price is given update it
                if (result.data.price !== undefined) {
                    this.priceData = {
                        totalPrice : result.data.price,
                        materialPrice : result.data.price,
                        pricePerSquareMeter : result.data.pricePerSquareMeter,
                        supply : {
                            status: result.data.status,
                            LabelStatus: result.data.LabelStatus,
                            labelDeliveryTime: result.data.labelDeliveryTime,
                        },
                    }
                } else {
                    this.priceData = null
                }
            }
        }
    }
}
</script>
